<template>
  <v-container fluid class="pl-12 pr-12">
    <v-layout column wrap>
      <v-row>
        <v-col>
          <p class="font-weight-black pl-3" style="font-size:32px">
            {{ $t('title.agency.salesList') }}
          </p>
        </v-col>
      </v-row>
      <v-row>
        <p class="pl-5" style="word-wrap: break-all; max-width:90vw">
          {{ $t('description.salesList.explanation1') }}
        </p>
      </v-row>
      <v-row>
        <v-col>
          <v-card outlined height="auto">
            <v-container>
              <v-row>
                <v-col class="py-0" style="max-width:315px">
                  <v-btn
                    class="font-weight-black mx-auto"
                    align="center"
                    width="100%"
                    height="96px"
                    max-width="300px"
                    color="next"
                    style="font-size:20px"
                    :dark="isSalesRegisterButtonEnabled"
                    :disabled="!isSalesRegisterButtonEnabled"
                    @click="onClickSalesRegisterButton()"
                    >{{ $t('button.salesRegister') }}</v-btn
                  >
                </v-col>
                <v-col class="py-0">
                  <p class="pl-3 mb-0">
                    {{ $t('description.salesList.salesRegister') }}
                  </p>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-col>
        <v-col>
          <v-card outlined height="auto">
            <v-container>
              <v-row>
                <v-col class="py-0" style="max-width:315px">
                  <v-btn
                    class="font-weight-black"
                    align="center"
                    width="100%"
                    height="96px"
                    max-width="300px"
                    color="next"
                    style="font-size:20px"
                    dark
                    slot="activator"
                    @click="onClickSalesSearchButton()"
                    >{{ $t('button.salesSearch') }}</v-btn
                  >
                </v-col>
                <v-col class="py-0">
                  <p class="pl-3 mb-0">
                    {{ $t('description.salesList.salesSearch') }}
                  </p>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card outlined height="auto">
            <v-container>
              <v-row>
                <v-col class="py-0" style="max-width:315px">
                  <v-btn
                    class="font-weight-black mx-auto"
                    align="center"
                    width="100%"
                    height="96px"
                    max-width="300px"
                    color="next"
                    style="font-size:20px"
                    :dark="isSalesBulkRegisterButtonEnabled"
                    :disabled="!isSalesBulkRegisterButtonEnabled"
                    @click="onClickSalesBulkRegisterButton()"
                    >{{ $t('button.salesBulkRegister') }}
                  </v-btn>
                </v-col>
                <v-col class="py-0">
                  <p class="pl-3 mb-0">
                    {{ $t('description.salesList.salesBulkRegister') }}
                  </p>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-col>
        <v-col>
          <v-card outlined height="auto">
            <v-container>
              <v-row>
                <v-col class="py-0" style="max-width:315px">
                  <v-btn
                    class="font-weight-black mx-auto"
                    align="center"
                    width="100%"
                    height="96px"
                    max-width="300px"
                    color="next"
                    style="font-size:20px"
                    :dark="isSalesBulkEditButtonEnabled"
                    :disabled="!isSalesBulkEditButtonEnabled"
                    @click="onClickSalesBulkUpdateButton()"
                    >{{ $t('button.salesBulkUpdate') }}
                  </v-btn>
                </v-col>
                <v-col class="py-0">
                  <p class="pl-3 mb-0">
                    {{ $t('description.salesList.salesBulkUpdate') }}
                  </p>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <p class="font-weight-black px-4 pt-4 mb-1" style="font-size:24px">
          {{ $t('label.salesList') }}
        </p>
      </v-row>
      <v-data-table
        calculate-widths
        class="elevation-1"
        :headers="headers"
        :items="records"
        :options.sync="options"
        :server-items-length="total"
        :loading="isLoading"
        :loading-text="$t('description.salesReading')"
        :no-data-text="$t('error.salesListNotFound')"
        :footer-props="{
          itemsPerPageText: '1ページあたりの行数',
          itemsPerPageOptions: itemsPerPageOptions,
        }"
      >
        <template v-slot:item.detail="{ item }" class="pa-0">
          <router-link
            :to="{
              name: 'SalesDetail',
              params: { sales_id: item.id },
            }"
            target="_blank"
            class="router-link-transparent"
            style="text-decoration:none"
          >
            <template>
              <v-chip
                class="font-weight-black float-center"
                color="next"
                dark
                ripple
                link
                >{{ $t('header.salesListTable.detail') }}</v-chip
              >
            </template>
          </router-link>
        </template>
      </v-data-table>
      <v-row>
            <v-col>
              <v-btn
                class="font-weight-black float-right"
                color="next"
                dark
                @click="onClickCsvDownloadButton()"
                >{{ $t('button.downloadCsv') }}</v-btn
              >
            </v-col>
      </v-row>
      <SalesSearchDialog
        @onSuccess="onSuccessSearchSales"
        @onClear="onClearSalesSearchCondition"
        ref="salesSearchDialog"
      ></SalesSearchDialog>
      <!-- 営業店登録ダイアログ -->
      <FormsDialog
        :showDialog="showCreateSalesDialog"
        :title="$t('title.agency.salesRegister')"
        :text="null"
        :subText="null"
        :negativeButtonTitle="$t('button.cancel')"
        :positiveButtonTitle="$t('button.register')"
        :onClickNegativeButton="cancelCreateSales"
        :onClickPositiveButton="createSales"
        :targets="createSalesTargets"
      />
      <SalesCsvFileSelectDialog
        @onSuccess="onSuccessCsvImport"
        ref="csvFileSelectDialog"
        :salesCodeList="salesCodeList"
        :isRegister="isRegister"
      />
      <ValidateErrorDialog ref="validateErrorDialog" />
      <BulkRegisterListDialog
        ref="bulkRegisterListDialog"
        @onSuccess="onBulkRegisterSales"
      />
      <BulkUpdateListDialog
        ref="bulkUpdateListDialog"
        @onSuccess="onBulkUpdateSales"
      />
      <ErrorDialog ref="errorDialog"></ErrorDialog>
      <CompletedDialog ref="completedDialog"></CompletedDialog>
    </v-layout>
  </v-container>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import {
  ErrorCode,
  salesListTableHeader,
  salesListDisplayDefault,
  MaxLength,
  MaxCsvExportRowCount,
  SalesSearchResultCsv
} from '@/lib/const';
import { Role } from '@/lib/role';
import CompletedDialog from '@/components/organisms/agency/CompletedDialog';
import ErrorDialog from '@/components/organisms/agency/ErrorDialog';
import FormsDialog from '@/components/organisms/agency/FormsDialog';
import SalesSearchDialog from '@/components/organisms/agency/SalesSearchDialog';
import {
  getSalesList,
  getSalesCodeList,
  getUpdateSalesList,
  postSales,
  postBulkRegisterSales,
  postBulkUpdateSales,
} from '@/apis/agency/sales';
import { downloadCsv, getCsvFileName } from '@/lib/csv';
import SalesCsvFileSelectDialog from '@/components/organisms/agency/SalesCsvFileSelectDialog';
import ValidateErrorDialog from '@/components/organisms/agency/ValidateErrorDialog';
import BulkRegisterListDialog from '@/components/organisms/agency/BulkRegisterListDialog';
import BulkUpdateListDialog from '@/components/organisms/agency/BulkUpdateListDialog';

export default {
  name: 'SalesList',
  components: {
    CompletedDialog,
    ErrorDialog,
    FormsDialog,
    SalesSearchDialog,
    SalesCsvFileSelectDialog,
    ValidateErrorDialog,
    BulkRegisterListDialog,
    BulkUpdateListDialog,
  },

  data: vm => ({
    headers: salesListTableHeader,
    records: [],

    // 案件一覧取得オプション
    options: {
      page: salesListDisplayDefault.page,
      itemsPerPage: salesListDisplayDefault.itemsPerPage,
      sortBy: [],
      sortDesc: [],
    },

    // ソートキー名のマッピング
    sortKey: {
      salesCode: 'salesCode',
    },

    // 営業店検索条件
    salesSearchCondition: {},

    // 1ページあたりの表示件数の選択肢
    itemsPerPageOptions: salesListDisplayDefault.itemsPerPageOptions,

    // 案件一覧取得件数
    total: 0,

    // 最大文字数
    MaxLength: MaxLength,

    // 営業店登録データ (デフォルト)
    createSalesDefaultTargets: [
      {
        value: null,
        targetName: 'salesCode',
        type: 'text',
        label: vm.$t('header.salesListTable.salesCode'),
        text: null,
        rule: `required|noSpace|pattern:salesCode`,
        key: 'salesCode',
      },
      {
        value: null,
        targetName: 'salesDepartmentName',
        type: 'text',
        label: vm.$t('header.salesListTable.salesDepartmentName'),
        text: null,
        rule: `required|max:${MaxLength.Default}`,
        key: 'salesDepartmentName',
      },
      {
        value: null,
        targetName: 'salesSectionName',
        type: 'text',
        label: vm.$t('header.salesListTable.salesSectionName'),
        text: null,
        rule: `required|max:${MaxLength.Default}`,
        key: 'salesSectionName',
      },
    ],

    // 営業店登録データ
    createSalesTargets: [],

    // 営業店登録ダイアログが表示されているかどうか
    showCreateSalesDialog: false,

    // 営業店登録ボタンが有効であるかどうか
    isSalesRegisterButtonEnabled: false,

    // 営業店一括登録ボタンが有効であるかどうか
    isSalesBulkRegisterButtonEnabled: false,

    // 営業店一括登録ボタンが有効であるかどうか
    isSalesBulkEditButtonEnabled: false,

    // ローディング中であるかどうか
    isLoading: true,

    // 登録済み営業店部担コード一覧
    salesCodeList: [],

    // 登録であるかどうか
    isRegister: false,

    // 一括登録営業店一覧
    bulkRegisterSalesList: {},

    // 一括更新営業店
    bulkUpdateSalesList: {},
  }),
  watch: {
    options: {
      handler() {
        this.fetchSales();
      },
      deep: true,
    },
  },
  mounted() {
    // ページ遷移共通: ナビゲーション一覧更新
    this.$emit('updateNavigation');

    // 営業店登録ボタンの有効状態をセットする
    this.$set(
      this,
      'isSalesRegisterButtonEnabled',
      Role.isButtonEnabled(this.userDetail(), 'salesRegister')
    );

    // 営業店登録ボタンの有効状態をセットする
    this.$set(
      this,
      'isSalesBulkRegisterButtonEnabled',
      Role.isButtonEnabled(this.userDetail(), 'salesBulkRegister')
    );

    // 営業店登録ボタンの有効状態をセットする
    this.$set(
      this,
      'isSalesBulkEditButtonEnabled',
      Role.isButtonEnabled(this.userDetail(), 'salesBulkEdit')
    );
  },
  methods: {
    ...mapGetters('user', ['userDetail']),
    ...mapActions('ui', ['setLoading']),

    // オプションの取得
    getOption() {
      return {
        perPage: this.options.itemsPerPage,
        pageNumber: this.options.page,
        ...(this.options.sortBy.length > 0 && {
          sortKey: this.sortKey[this.options.sortBy[0]],
        }),
        ...(this.options.sortDesc.length > 0 && {
          isDesc: this.options.sortDesc[0],
        }),
      };
    },

    // 営業店取得条件の取得
    getSalesCondition(salesSearchCondition) {
      return {
        ...salesSearchCondition,
      };
    },

    // 営業店一覧の取得
    async fetchSales() {
      // 営業店一覧の取得
      const sales = await getSalesList(
        this.getOption(),
        this.getSalesCondition(this.salesSearchCondition)
      ).catch(() => {
        // 空データをセットする
        this.$set(this, 'records', [{}]);
      });

      this.$set(this, 'isLoading', false);

      // 営業店一覧が取得できなかった場合、以降の処理を中止する
      if (!sales) return;

      // 表示値のマッピング
      const records = this.mapSales(sales);
      // 営業店一覧表示の更新
      this.$set(this, 'records', records);
      this.$set(this, 'total', sales.count);
    },

    // 営業店データのマッピング
    mapSales(sales) {
      return sales.rows.map(sales => ({
        detail: this.$t('button.view'),
        id: sales.id,
        salesCode: sales.salesCode,
        salesDepartmentName: sales.salesDepartmentName,
        salesSectionName: sales.salesSectionName,
      }));
    },

    // 営業店登録キャンセル
    cancelCreateSales() {
      this.$set(this, 'showCreateSalesDialog', false);
    },

    // 営業店登録
    async createSales(item) {
      const sales = {
        salesCode: item.salesCode,
        salesDepartmentName: item.salesDepartmentName,
        salesSectionName: item.salesSectionName,
      };
      // 営業店の登録
      const result = await postSales(sales).catch(error => {
        let message;
        switch (error.code) {
          case ErrorCode.DuplicateSalesCode:
            message = this.$t('error.duplicateSalesCode', {
              salesCode: item.salesCode,
            });
            break;
          default:
            message = this.$t('error.salesRegisterBadRequest');
        }
        this.$refs.errorDialog.open(
          this.$t('title.agency.salesRegisterError'),
          message
        );
      });

      // 営業店が登録できなかった場合、以降の処理を中止する
      if (!result) return;

      await this.fetchSales();

      this.$set(this, 'showCreateSalesDialog', false);

      // 営業店詳細画面へ遷移する
      this.$router.push({
        path: `/sales_detail/${result.id}`,
      });
    },

    // 営業店登録ボタン押下時
    onClickSalesRegisterButton() {
      const targets = JSON.parse(
        JSON.stringify(this.createSalesDefaultTargets)
      );
      const createSalesTargets = targets.map(target => {
        return target;
      });

      this.$set(this, 'createSalesTargets', createSalesTargets);
      this.$set(this, 'showCreateSalesDialog', true);
    },

    // 営業店検索ボタン押下時
    onClickSalesSearchButton() {
      this.$refs.salesSearchDialog.open();
    },

    // 営業店検索成功時
    async onSuccessSearchSales(condition) {
      // 営業店一覧の取得
      const sales = await getSalesList(
        this.getOption(),
        this.getSalesCondition(condition)
      ).catch(() => {
        this.$refs.salesSearchDialog.onErrorSearch();
      });

      // 営業店一覧が取得できなかった場合、以降の処理を中止する
      if (!sales) return;

      // 営業店が0件の場合、以降の処理を中止する
      if (sales.count === 0) {
        this.$refs.salesSearchDialog.onErrorSearch();
        return;
      }

      // 営業店一覧検索条件の保存
      this.$set(this, 'salesSearchCondition', condition);

      // ページングの初期化
      this.$set(this.options, 'page', salesListDisplayDefault.page);

      // 表示値のマッピング
      const records = this.mapSales(sales);
      // 営業店一覧表示の更新
      this.$set(this, 'records', records);
      this.$set(this, 'total', sales.count);

      this.$refs.salesSearchDialog.close();
    },

    // 営業店検索条件のクリア
    onClearSalesSearchCondition() {
      this.$set(this, 'salesSearchCondition', {});
    },
    // 一括登録ボタン押下時
    async onClickSalesBulkRegisterButton() {
      this.$set(this, 'salesCodeList', await getSalesCodeList());
      this.$set(this, 'isRegister', true);
      this.$refs.csvFileSelectDialog.open();
    },
    // CSV読み込み成功
    onSuccessCsvImport(csv, err) {
      if (this.isRegister) {
        this.onSuccessBulkRegisterCsvImport(csv, err);
      } else {
        this.onSuccessBulkUpdateCsvImport(csv, err);
      }
    },
    // 一括登録CSV読み込み
    async onSuccessBulkRegisterCsvImport(csv, err) {
      if (err.length > 0) {
        this.$refs.validateErrorDialog.open(
          this.$t('title.agency.csvParseError'),
          this.$t('error.csvParseError'),
          err
        );
      } else {
        const headers = this.headers.filter(header => header.value != 'detail');
        this.$set(this, 'bulkRegisterSalesList', csv);
        this.$refs.bulkRegisterListDialog.open(
          this.$t('title.agency.salesBulkRegisterConfirm'),
          this.$t('description.salesBulkRegisterConfirm'),
          headers,
          csv,
          this.itemsPerPageOptions
        );
      }

      this.$refs.csvFileSelectDialog.close();
    },
    // 一括登録
    async onBulkRegisterSales() {
      this.setLoading(true);

      const result = await postBulkRegisterSales(
        this.bulkRegisterSalesList
      ).catch(err => {
        console.log(err);
      });
      this.setLoading(false);

      if (result) {
        this.$refs.completedDialog.open(
          this.$t('title.agency.createResult'),
          this.$t('success.created')
        );
        await this.fetchSales();
      } else {
        this.$refs.errorDialog.open(
          this.$t('title.agency.createResult'),
          this.$t('error.createFailed')
        );
      }
      this.$refs.bulkRegisterListDialog.close();
    },
    // 一括更新ボタン押下時
    async onClickSalesBulkUpdateButton() {
      this.$set(this, 'salesCodeList', await getSalesCodeList());
      this.$set(this, 'isRegister', false);
      this.$refs.csvFileSelectDialog.open();
    },
    // 一括更新用CSV読み込み成功
    async onSuccessBulkUpdateCsvImport(csv, err) {
      this.setLoading(true);

      if (err.length > 0) {
        this.$refs.validateErrorDialog.open(
          this.$t('title.agency.csvParseError'),
          this.$t('error.csvParseError'),
          err
        );
      } else {
        const targetSalesCode = {
          salesCode: [],
        };
        csv.forEach(function(sales) {
          targetSalesCode.salesCode.push(sales.salesCode);
        });
        const targetSalesList = await getUpdateSalesList();
        const headers = this.headers.filter(header => header.value != 'detail');
        this.$set(this, 'bulkUpdateSalesList', csv);

        const newSales = csv.map(sales => {
          const oldSales = targetSalesList.find(
            target => target.salesCode === sales.salesCode
          );
          // 更新対象の特定
          const newSales = {
            salesCode: {
              value: sales.salesCode,
              class: 'text-center v-data-table__divider',
            },
            salesDepartmentName:
              oldSales.salesDepartmentName === sales.salesDepartmentName
                ? {
                    value: sales.salesDepartmentName,
                    class: 'text-center v-data-table__divider',
                  }
                : {
                    value:
                      oldSales.salesDepartmentName +
                      '→' +
                      sales.salesDepartmentName,
                    class: 'text-center v-data-table__divider update',
                  },
            salesSectionName:
              oldSales.salesSectionName === sales.salesSectionName
                ? {
                    value: sales.salesSectionName,
                    class: 'text-center v-data-table__divider',
                  }
                : {
                    value:
                      oldSales.salesSectionName + '→' + sales.salesSectionName,
                    class: 'text-center v-data-table__divider update',
                  },
          };
          return newSales;
        });
        this.$refs.bulkUpdateListDialog.open(
          this.$t('title.agency.salesBulkUpdateConfirm'),
          this.$t('description.salesBulkUpdateConfirm'),
          headers,
          newSales,
          this.itemsPerPageOptions
        );
      }
      this.setLoading(false);
      this.$refs.csvFileSelectDialog.close();
    },
    // 一括更新
    async onBulkUpdateSales() {
      this.setLoading(true);

      const result = await postBulkUpdateSales(this.bulkUpdateSalesList).catch(
        err => {
          console.log(err);
        }
      );
      this.setLoading(false);

      if (result) {
        this.$refs.completedDialog.open(
          this.$t('title.agency.updateResult'),
          this.$t('success.updated')
        );
        await this.fetchSales();
      } else {
        this.$refs.errorDialog.open(
          this.$t('title.agency.updateResult'),
          this.$t('error.updateFailed')
        );
      }
      this.$refs.bulkUpdateListDialog.close();
    },

    // CSVダウンロードボタンの押下時
    async onClickCsvDownloadButton() {
      // 営業店一覧の取得
      const sales = await getSalesList(
        null,
        this.getSalesCondition(this.salesSearchCondition)
      ).catch(() => {
        // 空データをセットする
        this.$set(this, 'records', [{}]);
      });

      // 出力データ数が最大行数を上回った場合
      if (sales.count > MaxCsvExportRowCount.Default) {
        this.$refs.errorDialog.open(
          this.$t('title.agency.csvExportError'),
          this.$t('error.invalidCsvMaxRowCount', {
            maxRowCount: MaxCsvExportRowCount.Default,
          })
        );

        return;
      }

      // CSV のヘッダー
      const headers = {};
      SalesSearchResultCsv.headers.forEach((header, index) => {
        // ヘッダー名をセットする
        headers[SalesSearchResultCsv.columns[index]] = header;
      });

      // CSV の各行
      const rows = sales.rows.map(sales => ({
        salesCode: sales.salesCode,
        salesDepartmentName: sales.salesDepartmentName,
        salesSectionName: sales.salesSectionName,
      }))

      // CSV をダウンロードする
      downloadCsv(rows, headers, getCsvFileName(this.$t('file.salesSearchResultCSV')));

      return;
    },
  },
};
</script>
<style>
.v_data_table_fill_width {
  width: 100%;
}
thead.v-data-table-header {
  background-color: #ddebf7;
}
thead.v-data-table-header > tr > th {
  font-size: 14px !important;
  text-align: center !important;
  padding: 0;
  white-space: pre-wrap;
}
.v_footer_justify_evenly {
  justify-content: space-evenly;
}
.v-data-table-header__icon {
  /* テーブルのソートアイコンを常に表示 */
  opacity: 1 !important;
  color: rgba(0, 0, 0, 0.2) !important;
}
th.active > .v-data-table-header__icon {
  color: rgba(0, 0, 0, 1) !important;
}
.v-data-table td {
  word-break: break-all;
}
/* 1ページあたりの行数を IE 11 でも表示させる */
.v-data-footer__select .v-select {
  width: 54px !important;
}
.v-data-table {
  overflow: hidden;
}
</style>
