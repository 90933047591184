<template>
  <Dialog ref="dialog" :title="$t('title.agency.salesSearch')" :color="'next'">
    <v-container class="pt-0 px-8">
      <v-form lazy-validation ref="form" @submit.prevent>
        <v-row>
          <v-col>
            <v-chip
              ripple
              class="font-weight-black float-right"
              @click="onClickClearButton()"
              >{{ $t('button.clear') }}<v-icon>mdi-delete</v-icon></v-chip
            >
          </v-col>
        </v-row>

        <v-row>
          <v-col :class="conditionStyle">
            <v-text-field
              v-model="condition.salesCode"
              :label="$t('header.salesMasterTable.salesCode')"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col :class="conditionStyle">
            <v-text-field
              v-model="condition.salesDepartmentName"
              :label="$t('header.salesMasterTable.salesDepartmentName')"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col :class="conditionStyle">
            <v-text-field
              v-model="condition.salesSectionName"
              :label="$t('header.salesMasterTable.salesSectionName')"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-form>
    </v-container>

    <template v-slot:footer>
      <v-btn width="33%" class="back" dark @click="close()">{{
        $t('button.cancel')
      }}</v-btn>
      <v-btn width="33%" class="next" dark @click="onClickSearchButton()">
        {{ $t('button.search') }}
      </v-btn>
    </template>
    <ErrorDialog ref="errorDialog"></ErrorDialog>
  </Dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import Dialog from '@/components/organisms/agency/Dialog';
import ErrorDialog from '@/components/organisms/agency/ErrorDialog';

export default {
  components: { Dialog, ErrorDialog },
  data() {
    return {
      // 営業店検索項目
      condition: {
        salesCode: '',
        salesDepartmentName: '',
        salesSectionName: '',
      },

      // 編集フォームのスタイル指定
      conditionStyle: ['my-0', 'py-0'],
    };
  },
  methods: {
    ...mapGetters('user', ['userDetail']),

    open() {
      this.$refs.dialog.open();
    },
    close() {
      this.$refs.dialog.close();
    },
    init() {
      this.condition.salesCode = '';
      this.condition.salesDepartmentName = '';
      this.condition.salesSectionName = '';
    },

    // クリアボタンの押下時
    onClickClearButton() {
      this.init();
      this.$emit('onClear');
    },

    // 検索ボタンの押下時
    onClickSearchButton() {
      // 検索項目
      const condition = {
        ...(this.condition.salesCode && {
          salesCode: this.condition.salesCode,
        }),
        ...(this.condition.salesDepartmentName && {
          salesDepartmentName: this.condition.salesDepartmentName,
        }),
        ...(this.condition.salesSectionName && {
          salesSectionName: this.condition.salesSectionName,
        }),
      };
      this.$emit('onSuccess', condition);
    },

    // 検索エラー時
    onErrorSearch() {
      this.$refs.errorDialog.open(
        this.$t('title.agency.salesSearchError'),
        this.$t('error.salesListNotFound')
      );
    },
  },
};
</script>
